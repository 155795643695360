import $ from 'jquery';
import Structure from '../modules/structure';

$.fn.howToBuy = function() {

    var jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const baseStructure = new Structure('howToBuy', container, {
            steps: {
                selector: '.how-to-buy-steps-list .how-to-buy-steps-item',
                many: true
            }
        }).structure;
        // do not allow click on links in list
        baseStructure.steps.each(function() {
            const step = $(this);
            step
            .on('mouseenter', function(e) {
                if (step.hasClass('active')) return;
                baseStructure.steps.removeClass('active');
                step.addClass('active');
            })
        })
    });

    return this;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.how-to-buy-js').howToBuy();
});
